<template>
  <div>
    <Teleport to="#header-buttons">
      <v-select
        v-model="currentState"
        class="mr-1 w-m-20"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :clearable="false"
        :searchable="false"
        :options="currentStateOptions"
      />
    </Teleport>
    <div class="d-flex justify-content-end mt-0" />
    <b-card no-body>
      <div class="m-2" />
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.limit"
        :columns="5"
        :table-props="{
          bordered: true,
          striped: true,
          tableClass: 'skeleton-loading-table',
        }"
      />
      <b-table
        v-else
        id="allGroups-table"
        hover
        :per-page="paginationObject.limit"
        :items="allGroups.result"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :sort-by.sync="sortBy"
      >
        <!-- Column: id -->
        <template #cell(Revenue_ID)="data">
          <span
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.id }}
          </span>
        </template>
        <template #cell(External_Ad)="data">
          <span
            class="font-weight-bold d-block text-nowrap text-truncate"
            style="max-width: 100px;"
            :title="data.item.pmp_ads.name"
          >
            {{ data.item.pmp_ads.name }}
          </span>
        </template>
        <template #cell(Venue_Name)="data">
          <span
            class="font-weight-bold d-block text-nowrap text-truncate"
            style="max-width: 100px;"
          >
            {{ data.item.venue.venue_name }}
          </span>
        </template>
        <template #cell(Business_Name)="data">
          <span
            class="font-weight-bold d-block text-nowrap text-truncate"
            style="max-width: 100px;"
          >
            {{ data.item.venue.user_has_group.name }}
          </span>
        </template>
        <template #cell(Revenue_Amount)="data">
          <span class="text-nowrap text-primary">
            $ {{ data.item.pmp_ad_revenue_amount }}
          </span>
        </template>
        <!-- Column: User -->
        <template #cell(Payment_Status)="data">
          <span
            class="text-nowrap d-inline-block text-truncate"
            style="max-width: 200px;"
          >
            {{ data.item.payment_sharing_status }}
          </span>
        </template>
        <!-- Column: Status -->
        <template #cell(Actions)="data">
          <span
            class="d-flex justify-content-center"
          >
            <b-button
              v-if="data.item.payment_sharing_status === PmpAdsRefundEnum.PENDING"
              id="show-btn"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-warning "
              size="sm"
              @click="payNow(data.item)"
            >
              Pay Now
            </b-button>
            <span
              v-else-if="data.item.payment_sharing_status === PmpAdsRefundEnum.PAID"
            >
              <span
                class="font-small-3 approval-tag-class"
              >Paid</span>
            </span>
            <span
              v-else
            >
              <span
                class="font-small-3 rejected-tag-class"
              >
                Failed
              </span>
            </span>
          </span>
        </template>
      </b-table>
    </b-card>
    <CustomPagination
      v-if="allGroups.total"
      :total="allGroups.total"
      :per-page="paginationObject.limit"
      :page="paginationObject.offset"
      @perPageChanged="perPageChanged"
      @pageChanged="pageChanged"
    />
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  BTable,
  VBToggle,
  BSkeletonTable,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import vSelect from 'vue-select'
import Teleport from 'vue2-teleport'
import 'vuesax/dist/vuesax.css'
import CustomPagination from '@/components/common/CustomPagination.vue'
import PmpAdsRefundEnum from '@/common/enums/pmpAdsRefundEnum'
import { showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BButton,
    BTable,
    BSkeletonTable,
    vSelect,
    Teleport,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      PmpAdsRefundEnum,
      sortBy: 'STATUS',
      spinner: false,
      allGroups: [],
      status: PmpAdsRefundEnum.PENDING,
      currentState: { key: 1, name: 'Pending', value: PmpAdsRefundEnum.PENDING },
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        {
          key: 'Revenue_ID', label: 'Venue Revenue',
        },
        {
          key: 'External_Ad', label: 'External Ad',
        },
        {
          key: 'Business_Name', label: 'Business Name',
        },
        {
          key: 'Venue_Name', label: 'Venue Name',
        },
        {
          key: 'Revenue_Amount', label: 'Revenue Amount',
        },
        {
          key: 'Payment_Status', label: 'Payment Status',
        },
        {
          key: 'Actions', label: 'Actions',
        },
      ],
      currentStateOptions: [
        { key: 1, name: 'Pending', value: PmpAdsRefundEnum.PENDING },
        { key: 2, name: 'Paid', value: PmpAdsRefundEnum.PAID },
        { key: 3, name: 'Failed', value: PmpAdsRefundEnum.FAILED },
      ],
    }
  },
  watch: {
    currentState: {
      handler(value) {
        if (value.value === PmpAdsRefundEnum.PENDING) {
          this.status = PmpAdsRefundEnum.PENDING
        } else if (value.value === PmpAdsRefundEnum.PAID) {
          this.status = PmpAdsRefundEnum.PAID
        } else {
          this.status = PmpAdsRefundEnum.FAILED
        }
        this.setPaginationToDefault()
        this.getAllPmpAdVenueOwnerRevenueList(this.paginationObject)
      },
    },
  },
  async mounted() {
    await this.getAllPmpAdVenueOwnerRevenueList(this.paginationObject)
  },
  methods: {
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllPmpAdVenueOwnerRevenueList(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllPmpAdVenueOwnerRevenueList(this.paginationObject)
    },
    async getAllPmpAdVenueOwnerRevenueList({ offset, limit }) {
      try {
        this.spinner = true
        this.allGroups = await this.$store.dispatch('pmpAds/getAllPmpAdRevenueForVenueOwnerList', { offset, limit, status: this.status })
      } catch (e) {
        showToast('Ad Revenue List', e.message, 'danger')
      }
      this.spinner = false
    },
    setPaginationToDefault() {
      this.paginationObject = {
        offset: 1,
        limit: 10,
        total: 0,
      }
    },
    payNow(data) {
      if (data) {
        if (data.venue.user_has_group.stripe_connect_account_id && data.venue.user_has_group.stripe_onBoarding) {
          this.$swal.fire({
            title: 'Revenue Sharing',
            text: `Are you sure you want to pay amount of $ ${data.pmp_ad_revenue_amount}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Pay Now',
            cancelButtonText: ' Cancel',
            reverseButtons: true,
            width: '650px',
          }).then(async result => {
            if (result.isConfirmed) {
              this.spinner = true
              try {
                await this.$axios.post('admin/pmp-ad/venue-revenue-share',
                  {
                    id: data.id,
                    stripe_connect_account_id: data.venue.user_has_group.stripe_connect_account_id,
                    pmp_ad_revenue_amount: Number(data.pmp_ad_revenue_amount),
                  },
                  {})
                await this.$swal.fire({
                  title: 'Payment Requested!',
                  text: 'Your request for payment has been delivered.',
                  icon: 'success',
                }).then(async result1 => {
                  if (result1.isConfirmed) {
                    window.location.reload()
                  }
                })
              } catch ({
                response: {
                  data: {
                    statusCode,
                    message,
                  },
                },
              }) {
                showToast('Revenue Sharing', message.toString(), 'danger')
              } finally {
                this.spinner = false
              }
            }
          })
        } else {
          this.$swal.fire({
            title: 'Stripe Account Not Connected',
            text: 'Please ask user to connect your stripe account to proceed',
            icon: 'warning',
            confirmButtonText: 'Ok',
            width: '650px',
          })
        }
      }
    },
  },
}
</script>

      <style lang="scss" scoped>
      .per-page-selector {
        width: 90px;
      }

      .invoice-filter-select {
        min-width: 190px;

        ::v-deep .vs__selected-options {
          flex-wrap: nowrap;
        }

        ::v-deep .vs__selected {
          width: 100px;
        }
      }
      </style>

      <style lang="scss">
      @import "@core/scss/vue/libs/vue-select.scss";

      .circular_image {
        border-radius: 40px;
        background-color: #eeedfd;
      }
      </style>
